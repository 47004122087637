/* Let CRA handle linting for sample app */
import React, { Component } from 'react';
// import Spinner from 'react-spinner';
import classNames from 'classnames';

import AccCore, {OpenTokSDK} from 'opentok-accelerator-core';
import annotationAccPack from 'opentok-annotation';
import archivingAccPack from 'opentok-archiving';

import screensharingAccPack from 'opentok-screen-sharing';
import jwt from "jwt-decode";

import { endCall, updateSerialNumber } from "../../services/meetingService.js";
import { endArchive, startArchive } from "../../services/archiveService.js";
import AllApi from "../../Service/services";
import 'opentok-solutions-css';

import logo from './logo.svg';
import config from './config.json';
import './App.css';
import {
  removeAllCursors,
  removeCursor,
  startCursorListener,
  stopCursorListener,
  updateRemoteCursor,
} from './cursor.js';
import { message } from 'opentok-accelerator-core/dist/logging.js';


var annotation = null;
var recording = null;
var screensharingLocal = null;
let callProperties = {
  insertMode: 'append',
  fitMode:"contain",
  width: "100%",
  height: '100%',
  showControls: false,
  style: {
    buttonDisplayMode: 'off'
  }
};

let callPropertiesBackground = {
  insertMode: 'append',
  fitMode:"cover",
  width: '100%',
  height: '100%',
  showControls: false,
  style: {
    buttonDisplayMode: 'off'
  }
};

const cursorUpdateMessageName = 'cursorPos';

const annotationOptionsOld = {
  screensharing:false,
  canvasContainer:"annotationContainerCustom",
  externalWindow:'.App-annotation-parent-container',
  absoluteParent:".app-annotation-container"
}

const archiving = {
  startURL: 'https://webhook.site/a8e9e93c-0718-4f84-aedf-f3586d6a816d',
  stopURL: 'https://webhook.site/a8e9e93c-0718-4f84-aedf-f3586d6a816d',
}


const annotationOptions = {
  screensharing:false,
  canvasContainer:".app-annotation-container",
  mobileInitiator:true,
  absoluteParent: '#cameraSubscriberContainer',
  toolbarItems:['pen', 'colors', 'shapes', 'text', 'clear','undo']
}

const screenshareOptions =  {
  extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
  screenSharingContainer:"screenPublisherContainer",
  annotation: true,
  externalWindow: false,
  dev: true,
  localScreenProperties: {
    insertMode: 'replace',
    width: '100%',
    height: '100%',
    display: "none",
    showControls: false,
    style: {
      buttonDisplayMode: 'on',
      display: "none"
    },
    videoSource: 'window',
    fitMode: 'contain' // Using default
  },
}

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeAnnotationButton = (activeCameraSubscribers)?activeCameraSubscribers:true;
  const activeSerialNumberInput = (activeCameraSubscribers)?activeCameraSubscribers:false;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;

  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    videoControls: classNames('video-call-control', { hidden: !active }),
    localAudioClass: classNames('local-audio-class', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('local-video-class', { hidden: !active, muted: !localVideoEnabled }),
    localCallClass: classNames('', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    annotationPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    activeAnnotationButton: classNames('annotation-wrap', { hidden: !active || !activeAnnotationButton }),
    activeSerialNumberInput: classNames('serial-number-wrap', { hidden: !active || !activeSerialNumberInput }),
    waitingForParticipant: classNames('', { hidden: !active || activeCameraSubscribers }),
    cameraSubscriberClass: classNames('video-container1', { hidden: !active || !activeCameraSubscribers },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
    annotationSubscriberClass: classNames('video-container', { hidden: true || !viewingSharedScreen || !active }),
  };
};

const connectingMask = () =>
  <div className="App-mask">
    {/* <Spinner /> */}
    <div className="message with-spinner">Connecting</div>
  </div>;

const startCallMask = (start, localVideoEnabled, toggleLocalVideo, localAudioEnabled, toggleLocalAudio) =>
  <div className="App-mask">

  <div className="custom-control custom-switch custom-switch-xl">
    <input type="checkbox" className="custom-control-input" id="customSwitch3" defaultChecked={localVideoEnabled} onChange={toggleLocalVideo} />
    <label className="custom-control-label" htmlFor="customSwitch3">Video</label>
  </div>
  <div className="custom-control custom-switch custom-switch-xl">
    <input type="checkbox" className="custom-control-input" id="customSwitch4" defaultChecked={localAudioEnabled} onChange={toggleLocalAudio} />
    <label className="custom-control-label" htmlFor="customSwitch4">Audio</label>
  </div>
  <div>
  <button className="message button clickable" onClick={start}>Click to Start Call </button>
  </div>

    
  </div>;

let otSDK;
let otCore;
const api = new AllApi();


class App extends Component {

  
  
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      name: "",
      userData: {},
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      serialNumber: "",
      annotationStarted: false,
      screenShareStarted: false,
      messagesCount:0,
      annotationPublisher: {},
      annotationStream: {},
      videoWidth:0,
      activeChat:false,
      videoHeight:0,
      session: null,
      meetingId: "",
      annotation: null,
      streamMap: null,
      localPublisherId: null,
    };
    
    this.startCall = this.startCall.bind(this);
    this.toggleChatWindow = this.toggleChatWindow.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.screenshot = this.screenshot.bind(this);
    this.changeSerialNumber = this.changeSerialNumber.bind(this);
    this.submitSerialNumber = this.submitSerialNumber.bind(this);
    this.printInfo = this.printInfo.bind(this);
    this.stopAnnotation = this.stopAnnotation.bind(this);
    this.endCallButtonClick = this.endCallButtonClick.bind(this);
    this.isJsonString = this.isJsonString.bind(this);
    this.screenShareToggle = this.screenShareToggle.bind(this);
    this.toggleRecording = this.toggleRecording.bind(this);
    this.messageCountChange = this.messageCountChange.bind(this);
    this.pointerStart = this.pointerStart.bind(this);
    // this.setCustomRes = this.setCustomRes.bind(this);
  }

  

  componentDidMount() {
    const { annotationPublisher } = this.state;
    config.sessionId = localStorage.getItem("vonage_session_id");
    config.token = localStorage.getItem("vonage_token");
    const token = localStorage.getItem("token");
    const meeting_id = localStorage.getItem("vonage_meetingId");
    let user = jwt(token);

    console.log("user=",user);

    otSDK = new OpenTokSDK(config);
    const otCoreOptions = {
      credentials: {
        apiKey: "06e31ffb-26e6-41a0-bb2b-e57d1f562b14",
        sessionId: localStorage.getItem("vonage_session_id"),
        token: localStorage.getItem("vonage_token"),
      }
    }
    otCore = new AccCore(otCoreOptions);
    console.log("otSDK",otSDK);
    console.log("otSDK",otCore);
    

    const session = otSDK.session;
    otSDK.connect().then((res) => {
      console.log("res",res);
      this.setState({ session, connected: true, userData: user, name: user.firstName+' '+user.lastName, meetingId: meeting_id })
      
      // this.startCall()
    });

   

  }
  

  pointerStart(){
    const { userData } = this.state;
    
    // const container = document.getElementById('cameraSubscriberContainer');
    // const callFrame = window.DailyIframe.createFrame(container, {
    //   showLeaveButton: true,
    //   activeSpeakerMode: false,
    // })


    const callback = (x, y, width, height, xpt, ypt) => {
      console.log("Final=",x,y,width, height, xpt, ypt)
      otSDK.signal("pointerLocation",{"x":x,"y":y,"width":width, "height":height, "xpt":xpt, "ypt":ypt, "userName":userData.firstName+' '+userData.lastName, "userId":userData.user_id });
      
    };

    startCursorListener(callback);
  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  startCall() {

        var { session, streamMap, active, annotationPublisher,activeChat,messagesCount,name } = this.state;
    
        const getChatTimeFormat = (date) => {
          var d = new Date();
          var hour = d.getHours() == 0 ? 12 : (d.getHours() > 12 ? d.getHours() - 12 : d.getHours());
          var min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
          var ampm = d.getHours() < 12 ? 'AM' : 'PM';
          return hour + ':' + min + ' ' + ampm;
          
        }
      

        const subscribeToStream = stream => {
          // console.log(stream);
          // console.log(streamMap[stream.id]);
          if (streamMap && streamMap[stream.id]) { return; }
          const type = stream.videoType;
          console.log("stream",stream)
          console.log("stream",callProperties)
          // callProperties.width = stream.videoDimensions.width+"px";
          // callProperties.height = stream.videoDimensions.height+"px";
          console.log("streamData",Object.values(otSDK.state().publishers.camera)[0]);

          

          if(this.isJsonString(stream.name)){
            
            const JSONdata = JSON.parse(stream.name);
            console.log("JSONdata",JSONdata);
            document.getElementById('cameraSubscriberContainer').style.aspectRatio = "9/16";
            if(JSONdata.name === "Annotation_Screen"){

              
              // callProperties.width = stream.videoDimensions.width+"px";
              // callProperties.height = stream.videoDimensions.height+"px";
              this.loadCanvas('div');
              console.log("stream",callProperties);
              otSDK.subscribe(stream, `app-annotation-container`,{...callProperties})
              .then(() => this.setState(otSDK.state()));
              annotation = new annotationAccPack(otSDK);
              
              annotation.start(otSDK.session,annotationOptions);
              annotation.linkCanvas("subscriber",annotationOptions.absoluteParent,annotationOptions);
              annotation.resizeCanvas();    
              this.setState({annotationStarted : true})
              annotation.resizeCanvas();    
              console.log(annotation);
            }else{
              otSDK.subscribe(stream, `cameraSubscriberContainer`, callProperties).then(() => this.setState(otSDK.state()));
              otSDK.subscribe(stream, `main-video-container`, callPropertiesBackground)
            }

          }else{
            document.getElementById('cameraSubscriberContainer').style.aspectRatio = "4/3";
            if(stream.name === "Annotation_Screen"){
              this.loadCanvas('div');
              console.log("stream",callProperties);
              otSDK.subscribe(stream, `app-annotation-container`,callProperties)
              .then(() => this.setState(otSDK.state()));
              annotation = new annotationAccPack(otSDK);
              
              annotation.start(otSDK.session,annotationOptions);
              annotation.linkCanvas("subscriber",annotationOptions.absoluteParent,annotationOptions);
              annotation.resizeCanvas();    
              this.setState({annotationStarted : true})
              annotation.resizeCanvas();    
              console.log(annotation);
            }else{
              otSDK.subscribe(stream, `cameraSubscriberContainer`, callProperties).then(() => this.setState(otSDK.state()));
              otSDK.subscribe(stream, `main-video-container`, callPropertiesBackground)
            }
          }
          
          
          };

        session.streams.forEach(subscribeToStream);
        console.log(otCore)
        let pointerData;
        // Subscribe to new streams and update state when streams are destroyed
        otSDK.on({
          'streamCreated' : ({ stream }) => subscribeToStream(stream),
          "signal:pointerLocation":({ data }) => {

            console.log(data); 
            pointerData = JSON.parse(data)
            if(!(pointerData.xpt<0 || pointerData.ypt<0 || pointerData.xpt>100 || pointerData.ypt>100)){
              updateRemoteCursor(pointerData.userId,pointerData.userName,pointerData.xpt,pointerData.ypt) 
            }
          },
          'streamDestroyed': ({ stream }) => {
            if(this.isJsonString(stream.name)){
              const JSONdata = JSON.parse(stream.name);
              if(JSONdata.name === "Annotation_Screen"){
                annotation.end();
                document.getElementById('app-annotation-container').remove();
                this.setState({annotationStarted : false})
              }
            }else if(stream.name === "Annotation_Screen"){
              annotation.end();
              document.getElementById('app-annotation-container').remove();
              this.setState({annotationStarted : false})
            }
            this.setState(otSDK.state())
          },
          'signal:stop-annotation': ({ stream }) => {
            // this.setState({annotationStarted : false})
            if(Object.keys(this.state.annotationPublisher).length > 0){
            this.setState({annotationStarted : false})
            otSDK.unpublish(this.state.annotationPublisher);
            this.setState({annotationPublisher: {}});
            annotation.end();
            document.getElementById('app-annotation-container').remove();
          }
        }
            
        });

        
    

        otSDK.session.on('signal:textChat', function(event) {
          var msg = document.createElement('div');
          var msgHistory = document.getElementById("chatHistory");
          const chatBy = event.from.connectionId === session.connection.connectionId ? 'sender-chat' : 'receiver-chat';

          console.log("event",event);
          console.log("activeChat",document.getElementsByClassName('video-calling-screen-left'));
          if(!document.getElementsByClassName('video-calling-screen-left')[0].classList.contains('active')){
            document.getElementById('badgeIcon').style="display:block"
          }

          if(event.data != null){



            if(false && msgHistory.lastElementChild && (msgHistory.lastElementChild.className === chatBy) && (msgHistory.lastElementChild.getAttribute('user') === event.from.connectionId)){
                
                const name = JSON.parse(event.data).name;
                const message = JSON.parse(event.data).message;

                var lastElement = msgHistory.lastElementChild;
                console.log(lastElement)
                var messageList = lastElement.getElementsByTagName('ul');
                console.log(messageList)

                var msg = document.createElement('li');
              
                msg.innerHTML = `<div class="info-box"><div class="description">`+message+`</div></div>`;

                messageList[0].appendChild(msg);



            }else if(chatBy == 'sender-chat'){

                    const name = JSON.parse(event.data).name;
                    const message = JSON.parse(event.data).message;

                    msg.setAttribute("class",'sender-chat');
                    msg.setAttribute("user",event.from.connectionId);
          

                    msg.innerHTML = `
                          <div class="info">
                            <ul>
                              <li>
                                <div class="info-box">
                                    <div class="author">`+name+`</div>
                                    <div class="description">`+message+`</div>
                                    <div class="time">`+getChatTimeFormat(event.from.creationTime)+`</div>
                                </div>
                            
                              </li>
                            </ul>
                          </div>`;
                          msgHistory.appendChild(msg);
                          {/* <div class="profile-pic">
                            <img src="assets/images/profile-pic.png" alt="profile-pic" />
                          </div> */}
                        
            }else{


              const name = JSON.parse(event.data).name;
              const message = JSON.parse(event.data).message;
              msg.setAttribute("user",event.from.connectionId);
              msg.setAttribute("class",'receiver-chat');
              msg.innerHTML = `<div class="info">
                            <ul>
                              <li>
                                <div class="info-box">
                                    <div class="author">`+name+`</div>
                                    <div class="description">`+message+`</div>
                                    <div class="time">`+getChatTimeFormat(event.from.creationTime)+`</div>
                                </div>
                                
                              </li>
                            </ul>
                          </div>
                          `;
                          
                          msgHistory.appendChild(msg);              
            }
          }
          
          msg.scrollIntoView();
        },this.state);
        

        otSDK.session.on("signal:disconnect-room", async function(event) {
          console.log(event.from.connectionId);
          console.log(otSDK.session.connection.connectionId);
          
          if (event.from.connectionId !== otSDK.session.connection.connectionId) {
            const token = localStorage.getItem("token");
            const obj = { Authorization: "Bearer " + token };
            await api.patchAxiosCalls("PATCH", "/v1/user/isFree", { isFree: true }, obj);
            otSDK.disconnect();
            stopCursorListener();
            // this.setState({ active: false });

            localStorage.removeItem("vonage_session_id");
            localStorage.removeItem("vonage_token");
            localStorage.removeItem("vonage_meetingId");
            window.location.assign('/dashboard/main');
          }
        });

        otSDK.on("signal:stop-annotation", function(event) {
          
          // if(otSDK.state().publishers.custom.cameraPublisherContainer.stream && Object.keys(otSDK.state().publishers.custom.cameraPublisherContainer.stream).length > 0){  
          
          //   otSDK.forceUnpublish(otSDK.state().publishers.custom.cameraPublisherContainer.stream);
          //   // this.setState({annotationPublisher: {}});
          //   annotation.end();
          //   document.getElementById('app-annotation-container').remove();
          //   // this.setState({annotationStarted : false})
          // }
    
      });

        otSDK.session.on("signal:otAnnotation_clear", function(event) {
          
          
            // if (event.from.connectionId !== otSDK.session.connection.connectionId) {
            //   // Only clear elements drawn by the sender's (from) Id
            //   annotation.clearCanvas(true, event.from.connectionId);
            // }
        });

    // Publish local camera streamconst events
    otSDK.publish('cameraPublisherContainer', callProperties)
    .then((publisher) => {
      console.log("publisher",publisher);
      this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));
      console.log("publisher",otSDK.state());
    }).catch(error => console.log(error));

    this.setState({ active: true });

  }




  toggleLocalAudio() {
    const { localPublisherId, publishers, localAudioEnabled } = this.state;
    const enabled = !localAudioEnabled;
    otSDK.enablePublisherAudio(enabled);
    this.setState({ localAudioEnabled: enabled });
  }

  messageCountChange() {
    const {messagesCount} = this.state;
    this.setState({messagesCount: messagesCount+1});
  }

  toggleLocalVideo() {
    const { localPublisherId, publishers, localVideoEnabled } = this.state;
    const enabled = !localVideoEnabled;
    otSDK.enablePublisherVideo(enabled);
    this.setState({ localVideoEnabled: enabled});
  }

  createCanvasStream = (img) => {
    const {annotationStarted } = this.state;
      var canvas = document.getElementById('app-annotation-container');
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      
      // var img1 = document.getElementById("annotation_image");
      (function loop() {
            setTimeout(loop, 1000 / 10)
            if(document.getElementById('app-annotation-container') == undefined){
              return;
            }
            ctx.drawImage(img, 0, 0);
          
          
      })();

      return canvas.captureStream(10).getVideoTracks()[0];
    
  }


  stopAnnotation() {
    
    const { annotationStarted, annotationPublisher } = this.state;
    console.log("annotationPublisher",annotationPublisher);
    
    
    if(Object.keys(annotationPublisher).length > 0){
      this.setState({annotationStarted : false})
      otSDK.unpublish(annotationPublisher);
      this.setState({annotationPublisher: {}});
      annotation.end();
      document.getElementById('app-annotation-container').remove();

    }else{
      otSDK.signal("stop-annotation",null);
      
    }
    
    

  }

  loadCanvas(type) {
      if(document.getElementById('app-annotation-container')){
        document.getElementById('app-annotation-container').remove()
      }
  
      var canvas = document.createElement(type);
      const div = document.getElementById('cameraSubscriberContainer'); 
      canvas.id     = "app-annotation-container";
      // canvas.width = 442;
      // canvas.height = 786;
      // canvas.style.zIndex   = 99;
      canvas.style.position = "absolute";
      div.appendChild(canvas)
  }

  screenshot() {
    const {annotationStarted } = this.state;
    this.loadCanvas('canvas');
    this.setState({annotationStarted : true})
    console.log("screenshot",Object.values(otSDK.state().publishers.camera));

    var imgData = Object.values(otSDK.state().publishers.camera)[0].getImgData();
    const height = Object.values(otSDK.state().publishers.camera)[0].videoHeight();
    const width = Object.values(otSDK.state().publishers.camera)[0].videoWidth();
  
    var img = document.createElement("img");

    img.setAttribute("src", "data:image/png;base64," + imgData);
    img.setAttribute("id", "annotation_image");
    document.getElementById("app-annotation-container").setAttribute('width',width);
    document.getElementById("app-annotation-container").setAttribute("height",height);
    document.getElementById("app-annotation-container").appendChild(img);


    // Publish local camera streamconst events
    otSDK.publish('cameraPublisherContainer', {
      // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
          videoSource: this.createCanvasStream(img),
          width: img.width,
          publishAudio:false,
          height: img.height,
          name: "Annotation_Screen",
          showControls: false,
          // videoSource: "window"
        })
    .then((publisher) => {
      console.log("publisher",publisher);
      this.setState({annotationPublisher: publisher});
      this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));
      console.log("publisher",otSDK.state());
    }).catch(error => console.log(error));

    annotation = new annotationAccPack(otSDK);
    annotation.start(otSDK.session,annotationOptions);
    annotation.linkCanvas("subscriber",annotationOptions.absoluteParent,annotationOptions);
    annotation.resizeCanvas();    
    console.log(annotation);
  }


  erase() {
    console.log(this.state);
  }
  

  printInfo() {
    console.log(this.state.subscribers);
    var imgDataHeight = Object.values(this.state.subscribers.camera)[0].videoHeight();
    var imgDataWidth = Object.values(this.state.subscribers.camera)[0].videoWidth();
    console.log("asdasdasd", imgDataHeight, imgDataWidth);
  }

  toggleChatWindow() {
    this.setState({activeChat: !this.state.activeChat})
    document.getElementById('badgeIcon').style="display:none"
    
  }

  async sendMessage() {
    const {name } = this.state;
    console.log(this.state);
    var data = document.getElementById('msg_input');
    console.log(data.value)
    console.log(data.value.length)
    if(data.value.length > 0){
      otSDK.signal("textChat",{"name":name,"message":data.value});
      document.getElementById('msg_input').value = '';
    }
    
  }

  enterPresses(e){
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.sendMessage();
    }
  }

  handleAppMessage(callFrame, e) {
    // Retrieve data from the event
    const { data } = e;
  
    // If the event type is not what we expect, early out
    if (data.type !== cursorUpdateMessageName) return;
  
    // If there's no valid position data in the event data,
    // throw an error
    if (!data.x || !data.y) {
      throw new Error('invalid cursor position data');
    }
  
    // Retrieve participant who sent this message
    const p = callFrame.participants()[e.fromId];
  
    // Retrieve the user name of the aprticipant
    // who sent this message, OR just their ID
    // if they don't have a user name set.
    let userName = e.fromId;
    if (p && p.user_name) {
      userName = p.user_name;
    }
    // Update the participant's remote cursor
    updateRemoteCursor(e.fromId, userName, data.x, data.y);
  }


  changeSerialNumber(event) {
    this.setState({serialNumber: event.target.value})
  }

  async submitSerialNumber(){

    const data =  {
      meetingId: this.state.meetingId,
      serialNumber: this.state.serialNumber,
    };

    let res = await updateSerialNumber(data).then((response) => {
      console.log("response",response.status);
      if(response.status === 200){
        document.getElementById('serial-number-update-message-id').classList.remove("hidden");
      }
    });
  }

  toggleRecording() {
    var { session,screenShareStarted } = this.state;
    archiving.session = session;
    archiving.controlAdded = false;
    archiving.appendControl = false;
    archiving.session=session;
    archiving.accPack = otCore;
    console.log("recording",archiving)
    recording = new archivingAccPack(archiving);
    console.log("recording",recording.start())
  }

  screenShareToggle() {
    var { session,screenShareStarted } = this.state;

    if(screenShareStarted === false){
      screensharingLocal = new screensharingAccPack({...screenshareOptions, session:session, accPack:otCore.getAccPack()});
      screensharingLocal.start()
    }else{
      screensharingLocal.end();
    }
    console.log(screensharingLocal.publisher.on("startScreenSharing",(data)=>{alert()}));
    console.log(screensharingLocal);
    
  }

  async endCallButtonClick(){
   
    const {meetingId} = this.state;
    
    otSDK.signal("disconnect-room",null);
   
    if (meetingId) {
      const data = {
        meeting_id: meetingId,
        meeting_status: (Object.keys(otSDK.state().streamMap).length<=1)?2:1,
      };
      const token = localStorage.getItem("token");
      const obj = { Authorization: "Bearer " + token };
      await api.patchAxiosCalls("PATCH", "/v1/user/isFree", { isFree: true }, obj);
      
      console.log(token);

  
      try {
        console.log(data)
        let res = await endCall(data);

        otSDK.disconnect();
        stopCursorListener();
        
        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        
        // this.setState({ active: false });

        window.location.assign('/dashboard/main');
        
      } catch (err) {
        otSDK.disconnect();
        stopCursorListener();
        // this.setState({ active: false });
        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        window.location.assign('/dashboard/main');
      }
        
    }
  };



  render() {
    const { connected, active,videoWidth,videoHeight,activeChat,annotationStarted,localAudioEnabled,localVideoEnabled,screenShareStarted,messagesCount } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      videoControls,
      cameraPublisherClass,
      screenPublisherClass,
      annotationPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
      waitingForParticipant,
      activeAnnotationButton,
      activeSerialNumberInput,
      annotationSubscriberClass
    } = containerClasses(this.state);



    return (
      <div id='content' className="video-call-app">
      <div className="video-calling-screen-wrap">
          <div className={activeChat?"video-calling-screen-left active":"video-calling-screen-left"}>
              <div className="video-call-screen">
                  <div className="video-call-frame">
                  <div id="main-video-container" className="App-video-container">
                    { !connected && connectingMask() }
                    { connected && !active && startCallMask(this.startCall, localVideoEnabled, this.toggleLocalVideo, localAudioEnabled, this.toggleLocalAudio)}
                    <div id="cameraPublisherContainer" style={{display: 'none'}}  className={cameraPublisherClass} />
                    <div id="screenPublisherContainer" style={{display: 'none'}}  className={screenPublisherClass} />
                    <div id="waitingForParticipantContainer" className={waitingForParticipant} >Waiting for participants to join!</div>
                    <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
                    <div id="screenSubscriberContainer" className={screenSubscriberClass} />
                    
                    
                  </div>

                      <div className="video-recorder hidden">
                          <div className="pulse-effect">
                            <div className="pulse-dot"></div>
                          </div>
                          <div className="timer">24:01:45</div>
                      </div>

                      {!screenShareStarted && <div className={activeAnnotationButton} >
                          {!annotationStarted ? <div className="annotation-cta" id='startAnnotationCustom' onClick={this.screenshot}>
                            <button className="cta-hover"><img src="assets/images/pen-icon.svg" alt="pen-icon" /> Start Annotation</button>
                          </div> : <div className="annotation-cta" id='startAnnotationCustom' onClick={this.stopAnnotation}>
                            <button className="cta-hover"><img src="assets/images/pen-icon.svg" alt="pen-icon" /> Stop Annotation</button>
                          </div>}
                          {/* <div className="eraser-cta">
                            <button className="cta-hover" id='OT_undo'><img src="assets/images/eraser-icon.svg" alt="eraser-icon" /></button>
                          </div> */}
                      </div>}

                      <div className={activeSerialNumberInput} >
                        <div className="input-group">
                          <input type="text" placeholder="Update Serial Number" onChange={this.changeSerialNumber} aria-label="Update Serial Number" aria-describedby="button-serial-number" />
                          <button className="send-btn" type="button" onClick={this.submitSerialNumber} id="button-serial-number"><img src="assets/images/send-icon.svg" alt="send-icon" /></button>
                          
                        </div>
                        <p id="serial-number-update-message-id" className="serial-number-update-message hidden">Serial Number Updated Successfully</p>
                      </div>

                      <div className="full-screen-wrap hidden">
                        <div className="fullscreen-cta">
                          <button className="cta-hover"><img src="assets/images/full-screen-icon.svg" alt="full-screen-icon" /></button>
                        </div> 
                      </div>

                      <div className="screen-capture-wrap hidden">
                        <div className="screen-capture-cta">
                          <button className="cta-hover"><img src="assets/images/screen-capture-icon.svg" alt="screen-capture-icon" /></button>
                        </div> 
                      </div>

                      {/* <div className="pointer-wrap">
                        <ul>
                          <li>
                              <a href="#" className="pointer-menu-opener"><img src="assets/images/pointer-white.svg" alt=""><img className="menu-arrow svg" src="assets/images/chevron-down-mobile.svg"  alt=""></a>
                            <ul className="pointer-menu">
                              <li>
                                <a href="#"><img src="assets/images/pointer-white.svg" alt=""/>Pointer<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""/></a>
                                <div className="pointer-sub-menu">
                                  <ul>
                                    <li><a href="#"><img src="assets/images/pointer-white.svg" alt=""/>small</a></li>
                                  </ul>
                                </div>
                              </li>
                              <li>
                                <a href="#"><img src="assets/images/hand-mobile.svg" className="svg" alt="">Hand<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""></a>
                                <div className="pointer-sub-menu">
                                  <ul>
                                    <li><a href="#"><img src="assets/images/pointer-white.svg" alt=""/>small</a></li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div> */}
                      <div className="refresh-wrap">
                        <div className="refresh-cta">
                          <button className="cta-hover"><img src="assets/images/refresh-icon.svg" alt="refresh-icon" /></button>
                        </div>
                      </div>
                  </div>
              </div>
              <div className={videoControls}>
                  <div className="control-listing">
                    <ul>
                    <div id="controls" className={controlClass}>
                      {/* <div className={localCallClass} onClick={this.endCall} />
                      <div className={localVideoClass} onClick={this.screenshot} />
                      <div onClick={this.printInfo} >Print Info</div>
                      <div className={localCallClass} id='startAnnotationCustom' onClick={this.startCustomAnnotation} /> */}
                      
                   
                      <li>
                        <div className="control-cta">
                          <button className={localAudioClass} onClick={this.toggleLocalAudio}> {localAudioEnabled?<img src="assets/images/Mic On.svg" alt="microphone-icon" />:<img src="assets/images/Mic Off.svg" alt="microphone-icon" />}</button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button className={localVideoClass} onClick={this.toggleLocalVideo}> {localVideoEnabled?<img src="assets/images/Video On.svg" alt="microphone-icon" />:<img src="assets/images/Video Off.svg" alt="microphone-icon" />}</button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button ><img src="assets/images/camera-rotate-icon.svg" alt="camera-rotate-icon" /></button>
                        </div>
                      </li> */}
                      <li>
                        <div className="control-cta end-call">
                          <button onClick={() => this.toggleRecording()}><img src="assets/images/stop-record-icon.svg" alt="" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          {!screenShareStarted?<button onClick={() => this.screenShareToggle()}><img src="assets/images/screen-share-mobile-white.svg"  alt="" /></button>:<button style={{border:"1px solid rgb(25, 125, 206)",background:"#e0e8ff"}} onClick={() => this.screenShareToggle()}><img  src="assets/images/screen-share-mobile.svg"  alt="" /></button>}
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button onClick={this.toggleChatWindow} className="chat-cta"><img src="assets/images/message-icon.svg" alt="message-icon" />
                          {console.log("messagesCount",messagesCount)}
                          {<span className="badge" id="badgeIcon" style={{display: "none"}}>&nbsp;</span>}
                          </button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button onClick={this.pointerStart} className="chat-cta"><img src="assets/images/message-icon.svg" alt="message-icon" />
                          </button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button><img src="assets/images/three-dots.svg" alt="" /></button>
                        </div>
                      </li> */}
                      </div>
                    </ul>
                  </div>
                  <div className="call-end-control-listing">
                    <ul>
                    <div id="controls" className={controlClass}>
                      <li>
                        <div className="control-cta end-call-cta">
                          <button onClick={() => this.endCallButtonClick()}>
                            {/* <img src="assets/images/three-dots.svg" alt="" />  */}
                            End Call</button>
                        </div>
                      </li>
                      </div>
                    </ul>
                  </div>
                  <div className="mobile-control">
                    <ul>
                      <li>
                        <div className="control-cta">
                          <button><img src="assets/images/video-icon-mobile.svg" alt="video-icon-mobile" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button><img src="assets/images/speaker-icon.svg" alt="speaker-icon" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button className="chat-cta-mobile"><img src="assets/images/chat-icon.svg" alt="chat-icon" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta cut-call">
                          <button><img src="assets/images/Phone-mobile.svg" alt="phone-icon" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button><img src="assets/images/pen-icon-mobile.svg" alt="pen-icon-mobile" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button><img src="assets/images/camera-flip.svg" alt="camera-flip" /></button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button className="three-dots"><img src="assets/images/three-dots.svg" alt="three-dots" /></button>
                        </div>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
          {/* {activeChat?"video-calling-screen-right":"video-calling-screen-right active"} */}
          <div className={activeChat?"video-calling-screen-right active":"video-calling-screen-right"}>
              <div className="video-chat-wrap">
                  <div className="chat-heading-wrap">
                    <div className="chat-heading">Chat</div>
                    <div className="close-cta">
                      <button className="close-chat" onClick={this.toggleChatWindow}>X</button>
                    </div>
                  </div>
                  <div id="chatHistory" className="chat-info">

                  </div>
              </div>
              <div className="chat-input-wrap">
                <div className="input-group">
                  {/* <button className="pin-btn"><img src="assets/images/pin-icon.svg" alt="pin-icon" /></button> */}
                    <input type="text" placeholder="Type Something..." id="msg_input" onKeyUp={(e) => this.enterPresses(e)} aria-label="Type Something..." aria-describedby="button-send-chat" />
                    <button className="send-btn" type="button" id="button-send-chat"><img src="assets/images/send-icon.svg" alt="send-icon" onClick={() => this.sendMessage()}/></button>
                </div>
              </div>  
          </div>
          <div id="App-screenshot-container" className="App-screenshot-container" />
          {/* <div className="App-annotation-parent-container">
            <div id="annotationPublisherContainer" className={annotationPublisherClass} />
            <div id="annotationSubscriberContainer" className={annotationSubscriberClass} />
              <canvas id="app-annotation-container" width="640" height="480" />
            </div> */}
          <div className="other-mobile-info">
            <div className="mobile-info-close-cta">
              <button className="mobile-info-close">X</button>
            </div>
              <ul>
                <li>
                  <button><img src="assets/images/stop-record-icon.svg" alt="" />Stop Recording</button>
                </li>
                <li>
                  <button><img src="assets/images/screen-share-mobile.svg" alt="screen-share" />Screen Share</button>
                </li>
                <li>
                  <button className="serial-no"><img src="assets/images/barcode.svg" alt="barcode" />Serial Number</button>
                </li>
                <li>
                  <button><img src="assets/images/capture-pic.svg" alt="capture-pic" />Capture Screenshot</button>
                </li>
              
                {/* <div className="pointer-wrap">
                  <ul className="pointer-menu">
                    <li>
                      <a href="#"><img src="assets/images/pointer-white.svg" className="svg" alt=""/>Pointer<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""/></a>
                      <div className="pointer-sub-menu">
                        <ul>
                          <li><a href="#"><img src="assets/images/pointer-white.svg" alt=""/>small</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="#"><img src="assets/images/hand-mobile.svg" className="svg" alt="">Hand<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""></a>
                      <div className="pointer-sub-menu">
                        <ul>
                          <li><a href="#"><img src="assets/images/pointer-white.svg" alt="asd" />small</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div> */}
              
                {/* <div className="serial-no-mobile">
                    <div className="serial-no-box">
                      <div className="top">
                        <div className="title">Update Serial Number</div>
                        <div className="serial-no-close-cta">
                          <button className="serial-no-close">X</button>
                        </div>
                      </div>
                      <div className="serial-number-wrap">
                        <div className="input-group">
                          <input type="text" placeholder="Enter here..." aria-label="Enter here..." aria-describedby="button-serial-number" />
                          <button className="send-btn" type="button" id="button-serial-number"><img src="assets/images/send-icon.svg" alt="send-icon" /></button>
                        </div>
                      </div>
                    </div>
                </div> */}
              </ul>
          </div>
      </div>
    </div>
      
      
    );
  }
}

export default App;
