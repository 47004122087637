import axios from "axios";
import { API_URL,VONAGE_SDK_ENDPOINT } from '../config/config'
import { getHeaderOptions } from './header';

const API_ENDPOINT = API_URL + '/v1/meeting';
const VONAGEAPI_ENDPOINT = VONAGE_SDK_ENDPOINT;
const API_KEY = "06e31ffb-26e6-41a0-bb2b-e57d1f562b14";

export function startArchive(data) {
    return axios.post(`${VONAGEAPI_ENDPOINT}/${API_KEY}/archive`, data);
}

export function stopArchive(data) {
    return axios.post(`${VONAGEAPI_ENDPOINT}/${API_KEY}/archive`, data);
}
