let mouseStopTimeout;
let contentElement;

export function updateRemoteCursor(userID, userName, posX, posY) {
  
  contentElement = document.getElementById('cameraSubscriberContainer');
  if (!contentElement) return;
  let cursorElement = document.getElementById(getCursorID(userID));
  if (!cursorElement) {
    cursorElement = createCursorElement(userID);
  }
  console.log("cursorElement",cursorElement)
  // const text = `↖️ ${userName}`;
  // if (cursorElement != null && cursorElement.innerText !== text) {
  //   cursorElement.innerText = text;
  // }
  console.log(posX,posY);
  cursorElement.style.left = `${posX}%`;
  cursorElement.style.cursor = `none`;
  cursorElement.style.top = `${posY}%`;
}

export function startCursorListener(callback) {
    
  contentElement = document.getElementById('cameraSubscriberContainer');
  const scrollElement = document.getElementById('cameraSubscriberContainer');
  console.log("contentElement",contentElement)
  console.log("scrollElement",contentElement)
  scrollElement.onmousemove = (e) => {
    clearTimeout(mouseStopTimeout);

    mouseStopTimeout = setTimeout((_) => {
      sendData(e, callback);
    }, 10);
  };
}

export function stopCursorListener() {
  const scrollElement = document.getElementById('scroll');
  // scrollElement.onmousemove = null;
}

export function removeCursor(userID) {
  const cursorElement = document.getElementById(getCursorID(userID));
  cursorElement.remove();
}

export function removeAllCursors() {
  const cursors = document.getElementsByClassName('cursor');
  for (let i = 0; i < cursors.length; i += 1) {
    cursors[i].remove();
  }
}

function sendData(e, callback) {
  if (!contentElement) return;
  // Send data relative to the user's position
  // in the content element
  const rect = contentElement.getBoundingClientRect();
  const x = e.clientX - rect.x;
  const y = e.clientY - rect.y;
  // const xpt = e.clientY - rect.y;
  // const ypt = e.clientY - rect.y;
  // console.log("e.clientX",x, rect.width, (x/rect.width)*100)
  // console.log("e.clientY",y, rect.height, (y/rect.height)*100)
  callback(x, y, rect.width, rect.height, (x/rect.width)*100, (y/rect.height)*100);
}

function createCursorElement(userID) {
  if (!contentElement) return null;
  const ele = document.createElement('div');
  ele.id = getCursorID(userID);
  ele.classList.add('cursor');
  contentElement.appendChild(ele);
  return ele;
}

function getCursorID(userID) {
  return `cursor-${userID}`;
}